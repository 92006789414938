import feathersClientUOA from "../plugins/feathers-client-uoa";
import { LogoService } from "./LogoService";
export const PrintTagService = {
    PrintTag: async (type, list, firstName, lastName, destination, company) => {
        var uoaimage = await LogoService.GetLogoBaseurl64(1);
        var pdfMake = require("../../public/js/pdfmake");
        if (pdfMake.vfs == undefined) {
            var pdfFonts = require("../../public/js/vfs_fonts");
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        if (type == "baggagenodata") {
            let arrayTable = [];
            let table = null;
            table = {
                margin: [-10, 12, 10, 10],
                table: {
                    widths: [60, 190, 105, 70, 100],
                    heights: 10,
                    body: [
                        [
                            {
                                text: "",
                                margin: [0, 0, 0, 0],
                                fontSize: 6,
                                border: [false, false, false, false],
                            },
                            {
                                text: "",
                                margin: [0, 0, 0, 0],
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                margin: [0, 0, 0, 0],
                                fontSize: 6,
                                border: [false, false, false, false],
                            },
                            {
                                text: "",
                                margin: [0, 0, 0, 0],
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                alignment: "center",
                                margin: [0, 15, 0, 0],
                                image: uoaimage.logo,
                                fit: [80, 80],
                                width: 60,
                                border: [false, false, false, false],
                                rowSpan: 5,
                            },
                        ],
                        [
                            {
                                text: "PASSENGER\nNAME : ",
                                fontSize: 8,
                                bold: true,
                                alignment: "right",
                                margin: [0, 7, 0, 0],
                                border: [false, false, true, false],
                            },
                            {
                                text: "",
                                fontSize: 16,
                                bold: true,
                                alignment: "center",
                                margin: [0, 5, 0, 5],
                                border: [true, true, true, true]
                            },
                            {
                                text: "DESTINATION : ",
                                fontSize: 8,
                                bold: true,
                                alignment: "right",
                                margin: [0, 7, 0, 0],
                                border: [true, false, true, false]
                            },
                            {
                                text: "",
                                fontSize: 16,
                                bold: true,
                                alignment: "center",
                                margin: [0, 5, 0, 5],
                                border: [true, true, true, true]
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [true, true, true, true]
                            },
                        ],
                        [
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false],
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, true, false, true]
                            },
                        ],
                        [
                            {
                                text: "COMPANY : ",
                                fontSize: 8,
                                alignment: "right",
                                bold: true,
                                margin: [0, 7, 0, 5],
                                border: [false, false, true, false],
                            },
                            {
                                text: "",

                                fontSize: 16,
                                bold: true,
                                alignment: "center",
                                margin: [0, 5, 0, 5],
                                border: [true, true, true, true]
                            },
                            {
                                text: "NO.OF BAG /\n CUSTOMER\nBELONGING :",
                                fontSize: 8,
                                alignment: "right",
                                bold: true,
                                margin: [0, 0, 0, 0],
                                border: [false, false, true, false],
                            },
                            {
                                text: "",
                                fontSize: 16,
                                bold: true,
                                alignment: "center",
                                margin: [0, 5, 0, 5],
                                border: [true, true, true, true]
                            },
                            {
                                text: "",
                                border: [true, true, true, true]
                            },
                        ],
                        [
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                margin: [0, 6, 0, 6],
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                        ],
                    ],
                    styles: {
                        header: {
                            bold: true,
                            alignment: "center",
                            decoration: "underline",
                            margin: [20, 20, 20, 20],
                        },
                    },
                },
            }
            arrayTable.push(table);

            var docDefinition = {
                pageSize: {
                    height: 141.73228346,
                    width: 566.92913386,
                },
                pageMargins: [0.5, 0.5, 0.5, 0.5],
                background: function (currentPage, pageSize) {
                    return [
                        {
                            canvas: [
                                { type: 'line', x1: 5, y1: 5, x2: 558, y2: 5, lineWidth: 1 }, //Up line
                                { type: 'line', x1: 5, y1: 5, x2: 5, y2: 135, lineWidth: 1 }, //Left line
                                { type: 'line', x1: 5, y1: 135, x2: 558, y2: 135, lineWidth: 1 }, //Bottom line
                                { type: 'line', x1: 558, y1: 5, x2: 558, y2: 135, lineWidth: 1 }, //Rigth line
                            ]
                        }
                    ]
                },
                content: arrayTable,
                styles: {
                    tableExample: {
                        fontSize: 10,
                        alignment: "left",
                        margin: [5, 5, 5, 5],
                        bold: true,
                    },
                    Borders: {
                        border: [false, true, false, true],
                    },
                },
            };

            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            const wait = pdfData => new Promise(resolve => {
                pdfData.getBlob(blob => {
                    resolve(blob);
                    printJS(URL.createObjectURL(blob))
                });
            });
            await wait(pdfDocGenerator);
        }

        if (type == "baggage") {
            if (list.length > 0) {
                let arrayTable = [];
                let i = 0;
                let total = list.length;
                let noOfBag = "";
                let pageBreak = '';

                if (list.length != 0) {
                    list.forEach(element => {
                        i++;
                        if (total == 1) {
                            noOfBag = "1";
                            pageBreak = '';
                        } else {
                            if (i == 1) {
                                pageBreak = '';
                            } else {
                                pageBreak = 'before'
                            }
                            noOfBag = i + "/" + total;
                        }
                        let table = null;

                        table = {
                            pageBreak: pageBreak,
                            margin: [-10, 12, 10, 10],
                            table: {
                                widths: [60, 190, 105, 70, 100],
                                heights: 10,
                                body: [
                                    [
                                        {
                                            text: "",
                                            margin: [0, 0, 0, 0],
                                            fontSize: 6,
                                            border: [false, false, false, false],
                                        },
                                        {
                                            text: "",
                                            margin: [0, 0, 0, 0],
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            margin: [0, 0, 0, 0],
                                            fontSize: 6,
                                            border: [false, false, false, false],
                                        },
                                        {
                                            text: "",
                                            margin: [0, 0, 0, 0],
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            alignment: "center",
                                            margin: [0, 15, 0, 0],
                                            image: uoaimage.logo,
                                            fit: [80, 80],
                                            width: 60,
                                            border: [false, false, false, false],
                                            rowSpan: 5,
                                        },
                                    ],
                                    [
                                        {
                                            text: "PASSENGER\nNAME : ",
                                            fontSize: 8,
                                            bold: true,
                                            alignment: "right",
                                            margin: [0, 7, 0, 0],
                                            border: [false, false, true, false],
                                        },
                                        {
                                            text: firstName + " " + lastName.substring(0, 1) + ".",
                                            fontSize: 16,
                                            bold: true,
                                            alignment: "center",
                                            margin: [0, 5, 0, 5],
                                            border: [true, true, true, true]
                                        },
                                        {
                                            text: "DESTINATION : ",
                                            fontSize: 8,
                                            bold: true,
                                            alignment: "right",
                                            margin: [0, 7, 0, 0],
                                            border: [true, false, true, false]
                                        },
                                        {
                                            text: destination,
                                            fontSize: 16,
                                            bold: true,
                                            alignment: "center",
                                            margin: [0, 5, 0, 5],
                                            border: [true, true, true, true]
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [true, true, true, true]
                                        },
                                    ],
                                    [
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false],
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, true, false, true]
                                        },
                                    ],
                                    [
                                        {
                                            text: "COMPANY : ",
                                            fontSize: 8,
                                            alignment: "right",
                                            bold: true,
                                            margin: [0, 7, 0, 5],
                                            border: [false, false, true, false],
                                        },
                                        {
                                            text: company,
                                            fontSize: 16,
                                            bold: true,
                                            alignment: "center",
                                            margin: [0, 5, 0, 5],
                                            border: [true, true, true, true]
                                        },
                                        {
                                            text: "NO.OF BAG /\n CUSTOMER\nBELONGING :",
                                            fontSize: 8,
                                            alignment: "right",
                                            bold: true,
                                            margin: [0, 0, 0, 0],
                                            border: [false, false, true, false],
                                        },
                                        {
                                            text: noOfBag,
                                            fontSize: 16,
                                            bold: true,
                                            alignment: "center",
                                            margin: [0, 5, 0, 5],
                                            border: [true, true, true, true]
                                        },
                                        {
                                            text: "",
                                            border: [true, true, true, true]
                                        },
                                    ],
                                    [
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            margin: [0, 6, 0, 6],
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                    ],
                                ],
                                styles: {
                                    header: {
                                        bold: true,
                                        alignment: "center",
                                        decoration: "underline",
                                        margin: [20, 20, 20, 20],
                                    },
                                },
                            },
                        }

                        arrayTable.push(table);
                    });
                }

                var docDefinition = {
                    pageSize: {
                        height: 141.73228346,
                        width: 566.92913386,
                    },
                    pageMargins: [0.5, 0.5, 0.5, 0.5],
                    background: function (currentPage, pageSize) {
                        return [
                            {
                                canvas: [
                                    { type: 'line', x1: 5, y1: 5, x2: 558, y2: 5, lineWidth: 1 }, //Up line
                                    { type: 'line', x1: 5, y1: 5, x2: 5, y2: 135, lineWidth: 1 }, //Left line
                                    { type: 'line', x1: 5, y1: 135, x2: 558, y2: 135, lineWidth: 1 }, //Bottom line
                                    { type: 'line', x1: 558, y1: 5, x2: 558, y2: 135, lineWidth: 1 }, //Rigth line
                                ]
                            }
                        ]
                    },
                    content: arrayTable,
                    styles: {
                        tableExample: {
                            fontSize: 10,
                            alignment: "left",
                            margin: [5, 5, 5, 5],
                            bold: true,
                        },
                        Borders: {
                            border: [false, true, false, true],
                        },
                    },
                };

                const pdfDocGenerator = pdfMake.createPdf(docDefinition);
                const wait = pdfData => new Promise(resolve => {
                    pdfData.getBlob(blob => {
                        resolve(blob);
                        printJS(URL.createObjectURL(blob))
                    });
                });
                await wait(pdfDocGenerator);
            }
        }

        if (type == "cargo") {
            if (list.length > 0) {
                let arrayTable = [];
                let i = 0;
                let total = list.length;
                let pageBreak = '';

                if (list.length != 0) {
                    list.forEach(item => {
                        i++;
                        if (total == 1) {
                            pageBreak = '';
                        } else {
                            if (i == 1) {
                                pageBreak = '';
                            } else {
                                pageBreak = 'before';
                            }
                        }
                        let table = null;

                        table = {
                            pageBreak: pageBreak,
                            margin: [-10, 12, 10, 10],
                            table: {
                                // widths: [60, 190, 105, 70, 100],
                                widths: [75, 190, 90, 70, 100],
                                heights: 10,
                                body: [
                                    [
                                        {
                                            text: "",
                                            margin: [0, 0, 0, 0],
                                            fontSize: 6,
                                            border: [false, false, false, false],
                                        },
                                        {
                                            text: "",
                                            margin: [0, 0, 0, 0],
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            margin: [0, 0, 0, 0],
                                            fontSize: 6,
                                            border: [false, false, false, false],
                                        },
                                        {
                                            text: "",
                                            margin: [0, 0, 0, 0],
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            alignment: "center",
                                            margin: [0, 15, 0, 0],
                                            image: uoaimage.logo,
                                            fit: [80, 80],
                                            width: 60,
                                            border: [false, false, false, false],
                                            rowSpan: 5,
                                        },
                                    ],
                                    [
                                        {
                                            text: "CONSIGNED TO : ",
                                            fontSize: 8,
                                            bold: true,
                                            alignment: "right",
                                            margin: [0, 7, 0, 0],
                                            border: [false, false, true, false],
                                        },
                                        {
                                            text: item.cg_contact,
                                            fontSize: 16,
                                            bold: true,
                                            alignment: "center",
                                            margin: [0, 5, 0, 5],
                                            border: [true, true, true, true]
                                        },
                                        {
                                            text: "DESTINATION : ",
                                            fontSize: 8,
                                            bold: true,
                                            alignment: "right",
                                            margin: [0, 7, 0, 0],
                                            border: [true, false, true, false]
                                        },
                                        {
                                            text: item.ap_name,
                                            fontSize: 16,
                                            bold: true,
                                            alignment: "center",
                                            margin: [0, 5, 0, 5],
                                            border: [true, true, true, true]
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [true, true, true, true]
                                        },
                                    ],
                                    [
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false],
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, true, false, true]
                                        },
                                    ],
                                    [
                                        {
                                            text: "DAN NO. : ",
                                            fontSize: 8,
                                            alignment: "right",
                                            bold: true,
                                            margin: [0, 7, 0, 5],
                                            border: [false, false, true, false],
                                        },
                                        {
                                            text: item.cg_dan_no,
                                            fontSize: 16,
                                            bold: true,
                                            alignment: "center",
                                            margin: [0, 5, 0, 5],
                                            border: [true, true, true, true]
                                        },
                                        {
                                            text: "NO.OF CUSTOMER\nBELONGING : ",
                                            fontSize: 8,
                                            alignment: "right",
                                            bold: true,
                                            margin: [0, 0, 0, 0],
                                            border: [false, false, true, false],
                                        },
                                        {
                                            text: item.noOfCargo,
                                            fontSize: 16,
                                            bold: true,
                                            alignment: "center",
                                            margin: [0, 5, 0, 5],
                                            border: [true, true, true, true]
                                        },
                                        {
                                            text: "",
                                            border: [true, true, true, true]
                                        },
                                    ],
                                    [
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            margin: [0, 6, 0, 6],
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                        {
                                            text: "",
                                            fontSize: 6,
                                            border: [false, false, false, false]
                                        },
                                    ],
                                ],
                                styles: {
                                    header: {
                                        bold: true,
                                        alignment: "center",
                                        decoration: "underline",
                                        margin: [20, 20, 20, 20],
                                    },
                                },
                            },
                        }

                        arrayTable.push(table);
                    });
                }

                var docDefinition = {
                    pageSize: {
                        height: 141.73228346,
                        width: 566.92913386,
                    },
                    pageMargins: [0.5, 0.5, 0.5, 0.5],
                    background: function (currentPage, pageSize) {
                        return [
                            {
                                canvas: [
                                    { type: 'line', x1: 5, y1: 5, x2: 558, y2: 5, lineWidth: 1 }, //Up line
                                    { type: 'line', x1: 5, y1: 5, x2: 5, y2: 135, lineWidth: 1 }, //Left line
                                    { type: 'line', x1: 5, y1: 135, x2: 558, y2: 135, lineWidth: 1 }, //Bottom line
                                    { type: 'line', x1: 558, y1: 5, x2: 558, y2: 135, lineWidth: 1 }, //Rigth line
                                ]
                            }
                        ]
                    },
                    content: arrayTable,
                    styles: {
                        tableExample: {
                            fontSize: 10,
                            alignment: "left",
                            margin: [5, 5, 5, 5],
                            bold: true,
                        },
                        Borders: {
                            border: [false, true, false, true],
                        },
                    },
                };

                const pdfDocGenerator = pdfMake.createPdf(docDefinition);
                const wait = pdfData => new Promise(resolve => {
                    pdfData.getBlob(blob => {
                        resolve(blob);
                        printJS(URL.createObjectURL(blob))
                    });
                });
                await wait(pdfDocGenerator);
            }
        }

        if (type == "cargonodata") {
            let arrayTable = [];
            let table = null;

            table = {
                pageBreak: '',
                margin: [-10, 12, 10, 10],
                table: {
                    // widths: [60, 190, 105, 70, 100],
                    widths: [75, 190, 90, 70, 100],
                    heights: 10,
                    body: [
                        [
                            {
                                text: "",
                                margin: [0, 0, 0, 0],
                                fontSize: 6,
                                border: [false, false, false, false],
                            },
                            {
                                text: "",
                                margin: [0, 0, 0, 0],
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                margin: [0, 0, 0, 0],
                                fontSize: 6,
                                border: [false, false, false, false],
                            },
                            {
                                text: "",
                                margin: [0, 0, 0, 0],
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                alignment: "center",
                                margin: [0, 15, 0, 0],
                                image: uoaimage.logo,
                                fit: [80, 80],
                                width: 60,
                                border: [false, false, false, false],
                                rowSpan: 5,
                            },
                        ],
                        [
                            {
                                text: "CONSIGNED TO : ",
                                fontSize: 8,
                                bold: true,
                                alignment: "right",
                                margin: [0, 7, 0, 0],
                                border: [false, false, true, false],
                            },
                            {
                                text: "CONSIGNED TO",
                                fontSize: 16,
                                bold: true,
                                alignment: "center",
                                margin: [0, 5, 0, 5],
                                border: [true, true, true, true],
                                color:"white"
                            },
                            {
                                text: "DESTINATION : ",
                                fontSize: 8,
                                bold: true,
                                alignment: "right",
                                margin: [0, 7, 0, 0],
                                border: [true, false, true, false]
                            },
                            {
                                text: "DES",
                                fontSize: 16,
                                bold: true,
                                alignment: "center",
                                margin: [0, 5, 0, 5],
                                border: [true, true, true, true],
                                color:"white"
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [true, true, true, true]
                            },
                        ],
                        [
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false],
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, true, false, true]
                            },
                        ],
                        [
                            {
                                text: "DAN NO. : ",
                                fontSize: 8,
                                alignment: "right",
                                bold: true,
                                margin: [0, 7, 0, 5],
                                border: [false, false, true, false],
                            },
                            {
                                text: "DAN NO.",
                                fontSize: 16,
                                bold: true,
                                alignment: "center",
                                margin: [0, 5, 0, 5],
                                border: [true, true, true, true],
                                color:"white"
                            },
                            {
                                text: "NO.OF CUSTOMER\nBELONGING : ",
                                fontSize: 8,
                                alignment: "right",
                                bold: true,
                                margin: [0, 0, 0, 0],
                                border: [false, false, true, false],
                            },
                            {
                                text: "NO.OF",
                                fontSize: 16,
                                bold: true,
                                alignment: "center",
                                margin: [0, 5, 0, 5],
                                border: [true, true, true, true],
                                color:"white"
                            },
                            {
                                text: "",
                                border: [true, true, true, true]
                            },
                        ],
                        [
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                margin: [0, 6, 0, 6],
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                            {
                                text: "",
                                fontSize: 6,
                                border: [false, false, false, false]
                            },
                        ],
                    ],
                    styles: {
                        header: {
                            bold: true,
                            alignment: "center",
                            decoration: "underline",
                            margin: [20, 20, 20, 20],
                        },
                    },
                },
            }

            arrayTable.push(table);

            var docDefinition = {
                pageSize: {
                    height: 141.73228346,
                    width: 566.92913386,
                },
                pageMargins: [0.5, 0.5, 0.5, 0.5],
                background: function (currentPage, pageSize) {
                    return [
                        {
                            canvas: [
                                { type: 'line', x1: 5, y1: 5, x2: 558, y2: 5, lineWidth: 1 }, //Up line
                                { type: 'line', x1: 5, y1: 5, x2: 5, y2: 135, lineWidth: 1 }, //Left line
                                { type: 'line', x1: 5, y1: 135, x2: 558, y2: 135, lineWidth: 1 }, //Bottom line
                                { type: 'line', x1: 558, y1: 5, x2: 558, y2: 135, lineWidth: 1 }, //Rigth line
                            ]
                        }
                    ]
                },
                content: arrayTable,
                styles: {
                    tableExample: {
                        fontSize: 10,
                        alignment: "left",
                        margin: [5, 5, 5, 5],
                        bold: true,
                    },
                    Borders: {
                        border: [false, true, false, true],
                    },
                },
            };

            const pdfDocGenerator = pdfMake.createPdf(docDefinition);
            const wait = pdfData => new Promise(resolve => {
                pdfData.getBlob(blob => {
                    resolve(blob);
                    printJS(URL.createObjectURL(blob))
                });
            });
            await wait(pdfDocGenerator);
        }
    }
}