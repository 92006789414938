<template>
  <div>
    <div id="outPassenger" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-24">Outbound Passengers</div>
        <div class="b-numPassenger">
          <span class="icon I-passengers"></span>
          <div class="T-size-20 SemiBold">
            {{ ArrlistPassenger.length }} Passengers
          </div>
        </div>
      </div>

      <!-- showAsGrid GRID -->
      <div class="box-S4" v-if="showAsGrid">
        <v-expansion-panels accordion class="b-accod" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Passenger List</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="wrap-passenger">
                <div class="box-S1-3" v-for="(item, index) in ArrlistPassenger" :key="item.IdPass">
                  <!-- รอ CheckIn -->
                  <div class="B-passenger" v-show="
                    item.p_checkin == null &&
                    item.p_offload != '1' &&
                    item.MedicalCheckupDayLeft > '90' &&
                    item.SafetyTrainingDayLeft > '90'
                  ">
                    <!-- เพิ่ม Class offload เมื่อ Passenger คนนี้ Offload / เพิ่ม Class complete เมื่อ Passenger คนนี้ มีการ Sign Checkin -->
                    <div>
                      <div class="b-person">
                        <div class="b-display" @click="SkipPDPA(item)">
                          <!-- <div class="B-display person1"></div> -->
                          <div v-if="
                            item.u_imageurl == undefined ||
                            item.u_imageurl == null
                          "></div>
                          <div class="B-display person1" v-else :style="{
                            backgroundImage:
                              'url(' + item.u_imageurl + ') !important',
                          }"></div>
                          <!-- เพิ่ม Class warning1 / warning2 เมื่อมี warning -->
                        </div>
                        <div class="b-name-company">
                          <p class="t-name T-size-14">{{ item.FullName }}</p>
                          <div class="t-company T-size-12" v-if="item.o_name != undefined">
                            Customer : {{ item.o_name }}
                          </div>
                          <div class="t-company T-size-12" v-if="item.c_name != undefined">
                            Company : {{ item.c_name }}
                          </div>
                        </div>
                        <div class="b-action">
                          <div class="b-btn">
                            <!-- <v-btn fab class="btn-edit" @click="EditItem(item,index)">
                              <span class="I-edit"></span>
                            </v-btn>
                            <v-btn fab class="primary btn-delete" @click="DeleteItem(item)">
                              <span class="I-bin"></span>
                            </v-btn>-->
                          </div>
                        </div>
                      </div>
                      <div class="b-detail">
                        <div class="t-title">Destiantion</div>
                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Departure From</span>
                            <p class="T-size-16">{{ item.departurefrom }}</p>
                          </div>
                          <div class="bC" v-if="item.checkboxinterfield == '1'">
                            <span class="T-size-12">Inter Field</span>
                            <i class="I-check"></i>
                          </div>
                          <div class="bR">
                            <span class="T-size-12">Destination</span>
                            <p class="T-size-16">{{ item.destination }}</p>
                          </div>
                        </div>
                      </div>

                      <div class="b-detail">
                        <div class="t-title">Personal Data</div>
                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Body Weight</span>
                            <p v-if="item.bodyweight != null" class="T-size-16">{{ item.bodyweight }} Kg.</p>
                            <p v-else class="T-size-16">-</p>
                          </div>
                          <div class="bC" v-if="item.as_id == 4">
                            <span class="T-size-12">Alcohol And Temperature</span>
                            <i class="I-check3"></i>
                          </div>
                          <div class="bR">
                            <span class="T-size-12">Baggage Weight</span>
                            <p class="T-size-16">
                              <!-- <span v-if="item.baggageweights != null">{{ item.baggageweights }}</span> -->
                            <ul v-for="w in item.baggageweights" :key="w.weight">
                              <li>{{ w.weight }}</li>
                            </ul>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="b-btnCheckin">
                        <v-btn class="theme-btn-even btn-checkin" v-show="item.p_checkin == null" :disabled="
                          readonly_super_flight
                        " @click="GotoPersonal(item.IdPass, item.destination)">
                          <span class="T-size-18">Check-in</span>
                        </v-btn>
                        <v-btn class="theme-btn-even btn-viewcheckin" v-show="item.p_checkin == '1'"
                          @click="GotoPersonal(item.IdPass, item.destination)">
                          <span class="T-size-18">View</span>
                        </v-btn>
                      </div>
                    </div>
                    <div class="b-offload">
                      <span class="t-text T-size-24">Off Load</span>
                      <v-btn class="theme-btn-even btn-recover">
                        <span class="T-size-18">Recover</span>
                      </v-btn>
                    </div>
                  </div>

                  <!-- Add -->
                  <!-- รอ CheckIn expired -->
                  <div class="B-passenger expired" v-show="
                    item.p_checkin == null &&
                    item.p_offload != '1' &&
                    (item.MedicalCheckupDayLeft <= '90' ||
                      item.SafetyTrainingDayLeft <= '90')
                  ">
                    <!-- เพิ่ม Class offload เมื่อ Passenger คนนี้ Offload / เพิ่ม Class complete เมื่อ Passenger คนนี้ มีการ Sign Checkin -->
                    <div>
                      <div class="b-person">
                        <div class="b-display">
                          <div class="B-display person1"></div>
                          <!-- เพิ่ม Class warning1 / warning2 เมื่อมี warning -->
                        </div>
                        <div class="b-name-company">
                          <p class="t-name T-size-14">{{ item.FullName }}</p>
                          <!-- Add -->
                          <div class="red--text">
                            <v-icon class="subheading T-size-18" v-show="
                              $CheckWarning.wranningSafetyTraining(
                                item.SafetyTrainingDayLeft
                              )
                            " color="red">mdi-clock-alert</v-icon>
                            <span class="T-size-12">
                              &nbsp;{{
                                $CheckWarning.wranningSafetyTraining(
                                  item.SafetyTrainingDayLeft
                                )
                              }}</span>
                          </div>
                          <div class="red--text">
                            <v-icon class="subheading T-size-18" v-show="
                              $CheckWarning.wranningMedicalCheckup(
                                item.MedicalCheckupDayLeft
                              )
                            " color="red">mdi-clock-alert</v-icon>
                            <span class="T-size-12">
                              &nbsp;{{
                                $CheckWarning.wranningMedicalCheckup(
                                  item.MedicalCheckupDayLeft
                                )
                              }}</span>
                          </div>
                          <div class="t-company T-size-12" v-if="item.o_name != undefined">
                            Customer : {{ item.o_name }}
                          </div>
                          <div class="t-company T-size-12" v-if="item.c_name != undefined">
                            Company : {{ item.c_name }}
                          </div>
                        </div>
                        <div class="b-action">
                          <div class="b-btn">
                            <!-- <v-btn fab class="btn-edit" @click="EditItem(item,index)">
                              <span class="I-edit"></span>
                            </v-btn>
                            <v-btn fab class="primary btn-delete" @click="DeleteItem(item)">
                              <span class="I-bin"></span>
                            </v-btn>-->
                          </div>
                        </div>
                      </div>
                      <div class="b-detail">
                        <div class="t-title">Destiantion</div>
                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Departure From</span>
                            <p class="T-size-16">{{ item.departurefrom }}</p>
                          </div>
                          <div class="bC" v-if="item.checkboxinterfield == '1'">
                            <span class="T-size-12">Inter Field</span>
                            <i class="I-check"></i>
                          </div>
                          <div class="bR">
                            <span class="T-size-12">Destination</span>
                            <p class="T-size-16">{{ item.destination }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="b-detail">
                        <div class="t-title">Personal Data</div>
                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Body Weight</span>
                            <p v-if="item.bodyweight != null" class="T-size-16">{{ item.bodyweight }} Kg.</p>
                            <p v-else class="T-size-16">-</p>
                          </div>
                          <div class="bC" v-if="item.as_id == 4">
                            <span class="T-size-12">Alcohol And Temperature</span>
                            <i class="I-check3"></i>
                          </div>
                          <div class="bR">
                            <span class="T-size-12">Baggage Weight</span>
                            <p class="T-size-16">
                              <!-- <span v-if="item.baggageweights != null">{{
                                item.baggageweights
                              }}</span> -->
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="b-btnCheckin">
                        <v-btn class="theme-btn-even btn-checkin" v-show="item.p_checkin == null"
                          @click="GotoPersonal(item.IdPass, item.destination)" :disabled="
                            readonly_super_flight
                          ">
                          <span class="T-size-18">Check-in</span>
                        </v-btn>
                        <v-btn class="theme-btn-even btn-viewcheckin" v-show="item.p_checkin == '1'"
                          @click="GotoPersonal(item.IdPass, item.destination)">
                          <span class="T-size-18">View</span>
                        </v-btn>
                      </div>
                    </div>
                    <div class="b-offload">
                      <span class="t-text T-size-24">Off Load</span>
                      <v-btn class="theme-btn-even btn-recover">
                        <span class="T-size-18">Recover</span>
                      </v-btn>
                    </div>
                  </div>

                  <!-- CheckIn สำเร็จ -->
                  <div class="B-passenger complete" v-show="
                    item.p_checkin == '1' &&
                    item.p_offload != '1' &&
                    item.MedicalCheckupDayLeft > '90' &&
                    item.SafetyTrainingDayLeft > '90'
                  ">
                    <!-- เพิ่ม Class offload เมื่อ Passenger คนนี้ Offload / เพิ่ม Class complete เมื่อ Passenger คนนี้ มีการ Sign Checkin -->
                    <div>
                      <div class="b-person">
                        <div class="b-display">
                          <div class="B-display person1"></div>
                          <!-- เพิ่ม Class warning1 / warning2 เมื่อมี warning -->
                        </div>
                        <div class="b-name-company">
                          <p class="t-name T-size-14">{{ item.FullName }}</p>
                          <div class="t-company T-size-12">
                            Customer : {{ item.o_name }}
                          </div>
                        </div>
                        <div class="b-action">
                          <div class="b-btn">
                            <!-- <v-btn fab class="btn-edit" @click="EditItem(item,index)">
                              <span class="I-edit"></span>
                            </v-btn>
                            <v-btn fab class="primary btn-delete" @click="DeleteItem(item)">
                              <span class="I-bin"></span>
                            </v-btn>-->
                          </div>
                        </div>
                      </div>
                      <div class="b-detail">
                        <div class="t-title">Destiantion</div>
                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Departure From</span>
                            <p class="T-size-16">{{ item.departurefrom }}</p>
                          </div>
                          <div class="bC" v-if="item.checkboxinterfield == '1'">
                            <span class="T-size-12">Inter Field</span>
                            <i class="I-check"></i>
                          </div>
                          <div class="bR">
                            <span class="T-size-12">Destination</span>
                            <p class="T-size-16">{{ item.destination }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="b-detail">
                        <div class="t-title">Personal Data</div>
                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Body Weight</span>
                            <p v-if="item.bodyweight != null" class="T-size-16">{{ item.bodyweight }} Kg.</p>
                            <p v-else class="T-size-16">-</p>
                          </div>
                          <div class="bC" v-if="item.as_id == 4">
                            <span class="T-size-12">Alcohol And Temperature</span>
                            <i class="I-check3"></i>
                          </div>
                          <div class="bR">
                            <span class="T-size-12">Baggage Weight</span>
                            <p class="T-size-16">
                              <!-- <span v-if="item.baggageweights != null">{{
                                item.baggageweights
                              }}</span> -->
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="b-btnCheckin">
                        <v-btn class="theme-btn-even btn-checkin" v-show="item.p_checkin == null"
                          @click="GotoPersonal(item.IdPass, item.destination)" :disabled="
                            readonly_super_flight
                          ">
                          <span class="T-size-18">Check-in</span>
                        </v-btn>
                        <v-btn class="theme-btn-even btn-viewcheckin" v-show="item.p_checkin == '1'"
                          @click="GotoPersonal(item.IdPass, item.destination)">
                          <span class="T-size-18">View</span>
                        </v-btn>
                      </div>
                    </div>
                    <div class="b-offload">
                      <span class="t-text T-size-24">Off Load</span>
                      <v-btn class="theme-btn-even btn-recover">
                        <span class="T-size-18">Recover</span>
                      </v-btn>
                    </div>
                  </div>

                  <!-- CheckIn สำเร็จ expired -->
                  <div class="B-passenger complete expired" v-show="
                    item.p_checkin == '1' &&
                    item.p_offload != '1' &&
                    (item.MedicalCheckupDayLeft <= '90' ||
                      item.SafetyTrainingDayLeft <= '90')
                  ">
                    <!-- เพิ่ม Class offload เมื่อ Passenger คนนี้ Offload / เพิ่ม Class complete เมื่อ Passenger คนนี้ มีการ Sign Checkin -->
                    <div>
                      <div class="b-person">
                        <div class="b-display">
                          <div class="B-display person1"></div>
                          <!-- เพิ่ม Class warning1 / warning2 เมื่อมี warning -->
                        </div>
                        <div class="b-name-company">
                          <p class="t-name T-size-14">{{ item.FullName }}</p>
                          <!-- Add -->
                          <div class="red--text">
                            <v-icon class="subheading T-size-18" v-show="
                              $CheckWarning.wranningSafetyTraining(
                                item.SafetyTrainingDayLeft
                              )
                            " color="red">mdi-clock-alert</v-icon>
                            <span class="T-size-12">
                              &nbsp;{{
                                $CheckWarning.wranningSafetyTraining(
                                  item.SafetyTrainingDayLeft
                                )
                              }}</span>
                          </div>
                          <div class="red--text">
                            <v-icon class="subheading T-size-18" v-show="
                              $CheckWarning.wranningMedicalCheckup(
                                item.MedicalCheckupDayLeft
                              )
                            " color="red">mdi-clock-alert</v-icon>
                            <span class="T-size-12">
                              &nbsp;{{
                                $CheckWarning.wranningMedicalCheckup(
                                  item.MedicalCheckupDayLeft
                                )
                              }}</span>
                          </div>
                          <div class="t-company T-size-12">
                            Customer : {{ item.o_name }}
                          </div>
                        </div>
                        <div class="b-action">
                          <div class="b-btn">
                            <!-- <v-btn fab class="btn-edit" @click="EditItem(item,index)">
                              <span class="I-edit"></span>
                            </v-btn>
                            <v-btn fab class="primary btn-delete" @click="DeleteItem(item)">
                              <span class="I-bin"></span>
                            </v-btn>-->
                          </div>
                        </div>
                      </div>
                      <div class="b-detail">
                        <div class="t-title">Destiantion</div>
                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Departure From</span>
                            <p class="T-size-16">{{ item.departurefrom }}</p>
                          </div>
                          <div class="bC" v-if="item.checkboxinterfield == '1'">
                            <span class="T-size-12">Inter Field</span>
                            <i class="I-check"></i>
                          </div>
                          <div class="bR">
                            <span class="T-size-12">Destination</span>
                            <p class="T-size-16">{{ item.destination }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="b-detail">
                        <div class="t-title">Personal Data</div>
                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Body Weight</span>
                            <p v-if="item.bodyweight != null" class="T-size-16">{{ item.bodyweight }} Kg.</p>
                            <p v-else class="T-size-16">-</p>
                          </div>
                          <div class="bC" v-if="item.as_id == 4">
                            <span class="T-size-12">Alcohol And Temperature</span>
                            <i class="I-check3"></i>
                          </div>
                          <div class="bR">
                            <span class="T-size-12">Baggage Weight</span>
                            <p class="T-size-16">
                              <!-- <span v-if="item.baggageweights != null">{{
                                item.baggageweights
                              }}</span> -->
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="b-btnCheckin">
                        <v-btn class="theme-btn-even btn-checkin" v-show="item.p_checkin == null"
                          @click="GotoPersonal(item.IdPass, item.destination)" :disabled="
                            readonly_super_flight
                          ">
                          <span class="T-size-18">Check-in</span>
                        </v-btn>
                        <v-btn class="theme-btn-even btn-viewcheckin" v-show="item.p_checkin == '1'"
                          @click="GotoPersonal(item.IdPass, item.destination)">
                          <span class="T-size-18">View</span>
                        </v-btn>
                      </div>
                    </div>
                    <div class="b-offload">
                      <span class="t-text T-size-24">Off Load</span>
                      <v-btn class="theme-btn-even btn-recover">
                        <span class="T-size-18">Recover</span>
                      </v-btn>
                    </div>
                  </div>

                  <div class="B-passenger offload" v-show="item.p_offload == '1'">
                    <!-- เพิ่ม Class offload เมื่อ Passenger คนนี้ Offload / เพิ่ม Class complete เมื่อ Passenger คนนี้ มีการ Sign Checkin -->
                    <div>
                      <div class="b-person">
                        <div class="b-display">
                          <div class="B-display person1"></div>
                          <!-- เพิ่ม Class warning1 / warning2 เมื่อมี warning -->
                        </div>
                        <div class="b-name-company">
                          <p class="t-name T-size-14">{{ item.FullName }}</p>
                          <div class="t-company T-size-12">
                            Customer : {{ item.o_name }}
                          </div>
                        </div>
                        <div class="b-action">
                          <div class="b-btn">
                            <!-- <v-btn fab class="btn-edit" @click="EditItem(item,index)">
                              <span class="I-edit"></span>
                            </v-btn>
                            <v-btn fab class="primary btn-delete" @click="DeleteItem(item)">
                              <span class="I-bin"></span>
                            </v-btn>-->
                          </div>
                        </div>
                      </div>
                      <div class="b-detail">
                        <div class="t-title">Destiantion</div>
                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Departure From</span>
                            <p class="T-size-16">{{ item.departurefrom }}</p>
                          </div>
                          <div class="bC" v-if="item.checkboxinterfield == '1'">
                            <span class="T-size-12">Inter Field</span>
                            <i class="I-check"></i>
                          </div>
                          <div class="bR">
                            <span class="T-size-12">Destination</span>
                            <p class="T-size-16">{{ item.destination }}</p>
                          </div>
                        </div>
                      </div>
                      <div class="b-detail">
                        <div class="t-title">Personal Data</div>
                        <div class="box-S4 flex-between-center t-detail">
                          <div class="bL">
                            <span class="T-size-12">Body Weight</span>
                            <p v-if="item.bodyweight != null" class="T-size-16">{{ item.bodyweight }} Kg.</p>
                            <p v-else class="T-size-16">-</p>
                          </div>
                          <div class="bC" v-if="item.as_id == 4">
                            <span class="T-size-12">Alcohol And Temperature</span>
                            <i class="I-check3"></i>
                          </div>
                          <div class="bR">
                            <span class="T-size-12">Baggage Weight</span>
                            <p class="T-size-16">
                              <!-- <span v-if="item.baggageweights != null">{{
                                item.baggageweights
                              }}</span> -->
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="b-btnCheckin">
                        <v-btn class="theme-btn-even btn-checkin" v-show="item.p_checkin == null"
                          @click="GotoPersonal(item.IdPass, item.destination)" :disabled="
                            readonly_super_flight
                          ">
                          <span class="T-size-18">Check-in</span>
                        </v-btn>
                        <v-btn class="theme-btn-even btn-viewcheckin" v-show="item.p_checkin == '1'"
                          @click="GotoPersonal(item.IdPass, item.destination)">
                          <span class="T-size-18">View</span>
                        </v-btn>
                      </div>
                    </div>
                    <div class="b-offload">
                      <span class="t-text T-size-24">Off Load</span>
                      <v-btn class="theme-btn-even btn-recover" @click="EditItemRecover(item, index)">
                        <span class="T-size-18">Recover</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div v-show="ArrlistPassenger.length == 0">
                  <div class="b-nodata">
                    <div class="t-nodata">Please Select Passengers</div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <!-- showAsGrid ROW -->
      <div class="box-S4" v-if="!showAsGrid">
        <v-expansion-panels accordion class="b-accod" v-model="panel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="panal-title T-size-20">Passenger List</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="wrap-passenger">
                <div class="box-4" v-for="item in ArrlistPassenger" :key="item.IdPass">
                  <div class="B-passenger" :class="item.retire" v-show="
                    item.MedicalCheckupDayLeft > '90' &&
                    item.SafetyTrainingDayLeft > '90'
                  ">
                    <div class="B-passenger TF">
                      <div class="b-person">

                        <div class="box-S1 fix-header" @click="SkipPDPA(item)">
                          <div v-if="
                            item.u_imageurl == undefined ||
                            item.u_imageurl == null
                          "></div>
                          <div class="B-display person1" v-else :style="{
                            backgroundImage:
                              'url(' + item.u_imageurl + ') !important',
                          }"></div>
                        </div>

                        <div class="box-S2">
                          <div class="b-name-company">
                            <p class="t-name T-size-14">{{ item.FullName }}</p>
                            <div class="t-company T-size-12">
                              <!-- <span v-if="item.o_name != undefined">{{
                                item.o_name
                              }}</span
                              >&nbsp; -->
                              <span v-if="item.c_name != undefined">{{ item.c_name }}</span><span v-else>No
                                Company</span>&nbsp;<span v-if="item.p_rfid" style="color:#800080">{{
                                  item.p_rfid
                                }}</span>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Departure From</span>
                            <p class="T-size-16">{{ item.departurefrom }}</p>
                          </div>
                        </div> -->

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC" v-if="item.checkboxinterfield == true">
                            <span class="T-size-12">Inter Field</span>
                            <i class="I-check"></i>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Destination</span>
                            <p class="T-size-16">{{ item.destination }}</p>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Check-in</span>
                            <div class="T-size-16 center" v-if="item.p_checkin == 1">
                              <v-icon>mdi-check</v-icon>
                              <p>{{ item.p_checkin_date | HHmmAdd7Hour }}</p>
                            </div>
                            <div class="T-size-16 center" v-else>
                              <v-icon>mdi-close</v-icon>
                            </div>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail" style="text-align: center">
                          <div class="bC" v-if="item.o_id== 4">
                            <span class="T-size-12">Nurse Status</span>
                            <div class="T-size-16 center" v-if="item.mubadala_status == 'Passed'">
                              <v-icon>mdi-check</v-icon>
                              <p>{{ item.mubadala_status }}</p>
                            </div>
                            <div class="T-size-16 center" v-else>
                              <v-icon>mdi-close</v-icon>
                              <p>{{ item.mubadala_status }}</p>
                            </div>
                          </div>

                          <div class="bC" v-else>
                            <span class="T-size-12">Alcohol</span>
                            <div class="T-size-16 center" v-if="item.as_name != null && item.as_name == 'Passed'">
                              <v-icon>mdi-check</v-icon>
                              <p>{{ item.as_name }}</p>
                            </div>
                            <div class="T-size-16 center" v-else>
                              <v-icon>mdi-close</v-icon>
                              <p>Not Pass</p>
                            </div>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Body Weight</span>
                            <p class="T-size-16 center" v-if="item.bodyweight != null">{{ item.bodyweight }} Kg.</p>
                            <p v-else class="T-size-16 center">-</p>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Baggage Weight</span>
                            <p class="T-size-16 center">
                              <span v-if="item.baggageweights.length > 0">
                                <ul v-for="w in item.baggageweights" :key="w.weight">
                                  <li>{{ w.weight }}</li>
                                </ul>
                              </span>
                              <span v-else>-</span>
                            </p>
                          </div>
                        </div>
                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Seat No</span>
                            <p class="T-size-16 center">
                              <span v-if="item.p_seat_no != null">{{
                                item.p_seat_no
                              }}</span>
                              <span v-else>-</span>
                            </p>
                          </div>
                        </div>
                        <div class="b-action">
                          <div class="b-btnCheckin">

                            <span v-if="item.p_offload == '1'">
                              <v-btn class="theme-btn-even offload" v-show="item.p_checkin == null" :disabled="
                                readonly_super_flight
                              " @click="GotoPersonal(item.IdPass, item.destination)">
                                <span class="T-size-18">Check-in</span>
                                <span class="T-size-18" style="color:red">&nbsp;&nbsp;[Offload]</span>
                              </v-btn>
                              <v-btn class="theme-btn-even offload" v-show="item.p_checkin == '1'"
                                @click="GotoPersonal(item.IdPass, item.destination)">
                                <span class="T-size-18">View</span>
                                <span class="T-size-18" style="color:red">&nbsp;&nbsp;[Offload]</span>
                              </v-btn>
                            </span>
                            <span v-else>
                              <v-btn class="theme-btn-even btn-checkin" v-show="item.p_checkin == null"
                                @click="GotoPersonal(item.IdPass, item.destination)" :disabled="
                                  readonly_super_flight
                                ">
                                <span class="T-size-18">Check-in</span>
                              </v-btn>
                              <v-btn class="theme-btn-even btn-viewcheckin" v-show="item.p_checkin == '1'"
                                @click="GotoPersonal(item.IdPass, item.destination)">
                                <span class="T-size-18">View</span>
                              </v-btn>
                            </span>
                            <v-btn class="theme-btn-even offload" @click="PrintLuggageTags(item)">
                              <v-icon>mdi-printer</v-icon>
                              <span class="T-size-18">Print Tags</span>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="B-passenger TF" v-show="
                    item.MedicalCheckupDayLeft <= '90' ||
                    item.SafetyTrainingDayLeft <= '90'
                  ">
                    <div class="B-passenger TF">
                      <div class="b-person">
                        <div class="box-S1 fix-header" @click="SkipPDPA(item)">
                          <div v-if="
                            item.u_imageurl == undefined ||
                            item.u_imageurl == null
                          "></div>
                          <div class="B-display person1" v-else :style="{
                            backgroundImage:
                              'url(' + item.u_imageurl + ') !important',
                          }"></div>
                        </div>

                        <div class="box-S2">
                          <div class="b-name-company">
                            <p class="t-name T-size-14">{{ item.FullName }}</p>
                            <!-- Add show only CPOC -->
                            <div class="red--text" v-show="item.o_id === 2">
                              <v-icon class="subheading T-size-18" v-show="
                                $CheckWarning.wranningSafetyTraining(
                                  item.SafetyTrainingDayLeft
                                )
                              " color="red">mdi-clock-alert</v-icon>
                              <span class="T-size-12">
                                &nbsp;{{
                                  $CheckWarning.wranningSafetyTraining(
                                    item.SafetyTrainingDayLeft
                                  )
                                }}</span>
                            </div>
                            <div class="red--text" v-show="item.o_id === 2">
                              <v-icon class="subheading T-size-18" v-show="
                                $CheckWarning.wranningMedicalCheckup(
                                  item.MedicalCheckupDayLeft
                                )
                              " color="red">mdi-clock-alert</v-icon>
                              <span class="T-size-12">
                                &nbsp;{{
                                  $CheckWarning.wranningMedicalCheckup(
                                    item.MedicalCheckupDayLeft
                                  )
                                }}</span>
                            </div>
                            <div class="t-company T-size-12">
                              <!-- <span v-if="item.o_name != undefined">{{
                                item.o_name
                              }}</span
                              >&nbsp; -->
                              <span v-if="item.c_name != undefined">{{ item.c_name }}</span><span v-else>No
                                Company</span>&nbsp;<span v-if="item.p_rfid" style="color:#800080">{{
                                  item.p_rfid
                                }}</span>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Departure From</span>
                            <p class="T-size-16">{{ item.departurefrom }}</p>
                          </div>
                        </div> -->

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC" v-if="item.checkboxinterfield == true">
                            <span class="T-size-12">Inter Field</span>
                            <i class="I-check"></i>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Destination</span>
                            <p class="T-size-16">{{ item.destination }}</p>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Check-in</span>
                            <div class="T-size-16 center" v-if="item.p_checkin == 1">
                              <v-icon>mdi-check</v-icon>
                              <p>{{ item.p_checkin_date | HHmmAdd7Hour }}</p>
                            </div>
                            <div class="T-size-16 center" v-else>
                              <v-icon>mdi-close</v-icon>
                            </div>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail" style="text-align: center">
                          <div class="bC" v-if="item.o_id == 4">
                            <span class="T-size-12">Nurse Status</span>
                            <div class="T-size-16 center" v-if="item.mubadala_status == 'Passed'">
                              <v-icon>mdi-check</v-icon>
                              <p>{{ item.mubadala_status }}</p>
                            </div>
                            <div class="T-size-16 center" v-else>
                              <v-icon>mdi-close</v-icon>
                              <p>{{ item.mubadala_status }}</p>
                            </div>
                          </div>

                          <div class="bC" v-else>
                            <span class="T-size-12">Alcohol</span>
                            <div class="T-size-16 center" v-if="item.as_name != null && item.as_name == 'Passed'">
                              <v-icon>mdi-check</v-icon>
                              <p>{{ item.as_name }}</p>
                            </div>
                            <div class="T-size-16 center" v-else>
                              <v-icon>mdi-close</v-icon>
                              <p>Not Pass</p>
                            </div>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Body Weight</span>
                            <p class="T-size-16 center" v-if="item.bodyweight != null">
                              {{ item.bodyweight }} Kg.
                            </p>
                            <p class="T-size-16 center" v-else>-</p>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Baggage Weight</span>
                            <p class="T-size-16 center">
                              <span v-if="item.baggageweights.length > 0">
                                <ul v-for="w in item.baggageweights" :key="w.weight">
                                  <li>{{ w.weight }}</li>
                                </ul>
                              </span>
                              <span v-else>-</span>
                            </p>
                          </div>
                        </div>

                        <div class="box-S1 flex-between-center t-detail">
                          <div class="bC">
                            <span class="T-size-12">Seat No</span>
                            <p class="T-size-16 center">
                              <span v-if="item.p_seat_no != null">{{
                                item.p_seat_no
                              }}</span>
                              <span v-else>-</span>
                            </p>
                          </div>
                        </div>

                        <div class="b-action">
                          <div class="b-btnCheckin">
                            <span v-if="item.p_offload == '1'">
                              <v-btn class="theme-btn-even offload" v-show="item.p_checkin == null" :disabled="
                                readonly_super_flight
                              " @click="GotoPersonal(item.IdPass, item.destination)">
                                <span class="T-size-18">Check-in</span>
                                <span class="T-size-18" style="color:red">&nbsp;&nbsp;[Offload]</span>
                              </v-btn>
                              <v-btn class="theme-btn-even offload" v-show="item.p_checkin == '1'"
                                @click="GotoPersonal(item.IdPass, item.destination)">
                                <span class="T-size-18">View</span>
                                <span class="T-size-18" style="color:red">&nbsp;&nbsp;[Offload]</span>
                              </v-btn>
                            </span>

                            <span v-else>
                              <v-btn class="theme-btn-even btn-checkin" v-show="item.p_checkin == null" :disabled="
                                readonly_super_flight
                              " @click="GotoPersonal(item.IdPass, item.destination)">
                                <span class="T-size-18">Check-in</span>
                              </v-btn>
                              <v-btn class="theme-btn-even btn-viewcheckin" v-show="item.p_checkin == '1'"
                                @click="GotoPersonal(item.IdPass, item.destination)">
                                <span class="T-size-18">View</span>
                              </v-btn>
                            </span>

                            <v-btn class="theme-btn-even offload" @click="PrintLuggageTags(item)">
                              <v-icon>mdi-printer</v-icon>
                              <span class="T-size-18">Print Tags</span>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="ArrlistPassenger.length == 0">
                  <div class="b-nodata">
                    <div class="t-nodata">Please Select Passengers</div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div class="box-S1-3">
        <!-- <v-btn class="theme-btn-even MGB10" 
        v-if="userdata.r_id == 5"
        @click="AddPassenger()">
          <span class="I-create"></span>
          <span>Add</span>
        </v-btn> -->
      </div>
      <!-- showAsGrid button -->
      <div class="box-S1">
        <v-switch v-model="showAsGrid" :label="`GRID`"> </v-switch>
      </div>
      <div class="line"></div>
    </div>
    <v-dialog v-model="dialogEvent" persistent max-width="400">
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="B-dialogform step">
            <div class="t-namepage">{{ formTitle }} Passenger</div>
            <div class="b-form">
              <v-stepper v-model="stepdialog">
                <v-stepper-header>
                  <v-stepper-step :complete="stepdialog > 1" step="1"></v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step :complete="stepdialog > 2" step="2"></v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <div class="b-contentstep">
                      <div class="box-btn-select" v-show="arraychoospassenger[0].FullName == ''">
                        <v-btn class="theme-btn-even btn-select" @click="OpenDialogSelectPassenger()">
                          <span class="I-create"></span>
                          <span>Select Passenger</span>
                        </v-btn>
                      </div>
                      <div class="B-passenger TF">
                        <div v-show="arraychoospassenger[0].FullName != ''">
                          <div class="b-person">
                            <div class="b-display">
                              <div class="B-display person1"></div>
                            </div>
                            <div class="b-name-company">
                              <p class="t-name">
                                {{ arraychoospassenger[0].FullName }}
                              </p>
                              <div class="t-company T-size-12">
                                Customer : {{ arraychoospassenger[0].o_name }}
                              </div>
                            </div>
                          </div>
                          <div class="b-detail">
                            <div class="t-title">Remark</div>
                            <div class="box-S4 flex-between-center t-detail">
                              <v-text-field label="Remark" v-model="remark"></v-text-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box-btn">
                      <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogEvent = false">
                        <span class="T-size-18">cancel</span>
                      </v-btn>
                      <v-btn class="theme-btn-even btn-ok Bsize100" @click="GotoStepDialog2()">
                        <span class="T-size-18">next</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <div class="b-contentstep">
                      <div class="B-passenger TF">
                        <div class="b-detail">
                          <div class="t-title">Destination</div>
                          <div class="box-S4 flex-between-center">
                            <div class="box-S2 t-detail">
                              <v-select class="hideMessage" v-model="departurefrom" :items="itemsListAirport"
                                label="Departure From" item-text="ap_name" item-value="ap_id" return-object></v-select>
                            </div>
                            <div class="box-S2 t-detail">
                              <v-select class="hideMessage" v-model="destination" :items="itemsListAirport"
                                label="Destination" item-text="ap_name" item-value="ap_id" return-object></v-select>
                            </div>
                          </div>
                          <div class="b-checkbox">
                            <v-checkbox v-model="checkboxinterfield" label="Inter Field"></v-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="box-btn">
                      <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="stepdialog = 1">
                        <span class="T-size-18">cancel</span>
                      </v-btn>
                      <v-btn class="theme-btn-even btn-save Bsize100" text @click="dialogEvent = false">
                        <span class="T-size-18" @click="SaveToArrayPassenger()">Finish</span>
                      </v-btn>
                    </div>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- Select Passenger -->
    <v-dialog v-model="dialogSelectPassenger" persistent max-width="800" fullscreen>
      <v-card id="dialog" class="TF type2">
        <div class="b-content">
          <div class="box-S4 flex-between-center">
            <div class="N-Page T-size-36 TF">Select Passenger</div>
            <v-btn class="theme-btn-even" @click="dialogSelectPassenger = false">
              <span>Close</span>
            </v-btn>
          </div>
          <div class="box-S4">
            <v-text-field v-model="search" append-icon="mdi-account-search" class="hideMessage"
              label="Search by Staff Code"></v-text-field>
          </div>
          <div class="box-S4 noPadding flex-between-center">
            <v-data-table class="y-scroll" :headers="headers" :search="search" :items="userItem" :items-per-page="5">
              <template v-slot:item.action="{ item }">
                <div class="b-action">
                  <div class="b-btn">
                    <v-btn fab class="btn-edit" @click="ChoosePassenger(item)">
                      <span class="I-add2"></span>
                    </v-btn>
                  </div>
                </div>
              </template>
              <template v-slot:item.u_imageurl="{ item }">
                <img class="B-display In-table" :src="item.u_imageurl" />
              </template>
            </v-data-table>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog Confirm to Skip PDPA -->
    <v-dialog v-model="dialogConfirmToSkipPDPA" persistent max-width="300">
      <v-card id="dialog" class="TF type1">
        <div class="b-content">
          <div class="B-dialog warning3">
            <div class="t-title">{{ passSelect.FullName }}</div>
            <div class="t-des">
              Are you sure you want to<br/>skip PDPA?
            </div>
          </div>
          <div class="box-btn">
            <v-btn class="theme-btn-even btn-cancel Bsize100" text @click="dialogConfirmToSkipPDPA = false">
              <span class="T-size-18">cancel</span>
            </v-btn>
            <v-btn class="theme-btn-even btn-save Bsize100" text @click="ConfirmToSkipPDPA()">
              <span class="T-size-18">Confirm</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClientUOA from "../../plugins/feathers-client-uoa";
import printJS from 'print-js'
import { PrintTagService } from "../../utils/PrintTagService";

export default {
  data: () => ({
    o_name: "",
    o_id:null,

    showAsGrid: false,

    userdata: "",
    modeEvent: "",
    dialogEvent: false,
    dialogSelectPassenger: false,
    stepdialog: 1,
    headers: [
      { value: "u_imageurl", text: "", sortable: false },
      // { value: "u_staffcode", text: "Staff Code", sortable: false },
      { value: "u_firstname", text: "First Name", sortable: true },
      { value: "u_lastname", text: "Last Name", sortable: true },
      // { value: "u_username", text: "Username", sortable: false },
      { value: "r_name", text: "Position", sortable: false },
      { value: "c_name", text: "Company", sortable: false },
      { value: "action", text: "", sortable: false },
    ],
    search: "",
    itemsListAirport: [],
    itemsDataPassenger: [],
    userItem: [],
    // input Model
    remark: "",
    departurefrom: "",
    destination: "",
    checkboxinterfield: false,
    ArrlistPassenger: [],
    arraychoospassenger: [
      {
        c_id: "",
        p_imageurl: "",
        u_id: "",
        FullName: "",
        c_name: "",
        as_id: "",
      },
    ],
    sumbodyweight: 0,
    sumbaggageweight: 0,
    sumtotal: 0,
    chooseItemPassengerIndex: null,
    chooseItemPassenger: null,
    IdPass: null,
    idflight: null,
    panel: [0],

    //Add
    safetyTrainingDayLeft: 0,
    medicalCheckupDayLeft: 0,
    readonly_super_flight: false,
    dialogConfirmToSkipPDPA: false,

    passSelect: {
      FullName: "",
    },

    ArrlistAddBaggage: [],
  }),
  // props: ["TypePassenger"],
  //Edit
  props: ["flightId"],
  components: {},
  async mounted() {
    //this.userdata = JSON.parse(localStorage.getItem("user"));
    //Edit
    this.userdata = this.$CurrentUser.GetCurrentUser();
    if (this.userdata.r_id == 7) {
      this.showAsGrid = false;
    }

    this.renderUI();
    this.SelectListAircraft();

    await feathersClientUOA.service("passenger").on("created", (passenger) => {
      this.DataPassengerFromDB(this.flightId);
    });
    await feathersClientUOA.service("passenger").on("patched", (passenger) => {
      this.DataPassengerFromDB(this.flightId);
    });
    await feathersClientUOA.service("passenger").on("removed", (passenger) => {
      this.DataPassengerFromDB(this.flightId);
    });
    await feathersClientUOA.service("baggage").on("created", (passenger) => {
      this.DataPassengerFromDB(this.flightId);
    });
    await feathersClientUOA.service("baggage").on("patched", (passenger) => {
      this.DataPassengerFromDB(this.flightId);
    });
    await feathersClientUOA.service("baggage").on("removed", (passenger) => {
      this.DataPassengerFromDB(this.flightId);
    });
    await feathersClientUOA.service("alcoholstatus").on("patched", (passenger) => {
      this.DataPassengerFromDB(this.flightId);
    });
  },
  computed: {
    formTitle() {
      return this.modeEvent === "create" ? "Add" : "Edit";
    },
  },
  methods: {
    //Add
    OpenDialogSelectPassenger() {
      this.search = "";
      this.dialogSelectPassenger = true;
    },
    async SelectListAircraft() {
      try {
        var q = {};
        q.retire = "0";
        var res = await feathersClientUOA.service("airports").find({ query: q });
        this.itemsListAirport = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async renderUI() {
      try {
        const { user } = await feathersClientUOA.get("authentication");
        var queryFlight = {};
        queryFlight.f_id = this.flightId;
        var resFlight = await feathersClientUOA
          .service("flight")
          .find({ query: queryFlight });
        var customerID = resFlight.data[0].o_id;
        var resCustomer = await feathersClientUOA
          .service("customer")
          .get(customerID, { query: {} });
        this.o_name = resCustomer.o_name;
        this.o_id = resCustomer.o_id;

        //User
        this.search = "";
        const q = {};
        if (
          this.userdata.r_id != 4 &&
          this.userdata.r_id != 5 &&
          this.userdata.r_id != 21
        ) {
          q.o_id = customerID;
        }
        q.r_id = 3;
        let res = await feathersClientUOA
          .service("viewuser")
          .find({ query: q });
        this.userItem = res.data;
      } catch (err) { }
    },
    async AddPassenger() {
      this.modeEvent = "create";
      this.dialogEvent = true;
      this.stepdialog = 1;
      this.arraychoospassenger = [
        {
          c_id: "",
          p_imageurl: "",
          u_id: "",
          FullName: "",
          c_name: "",
          u_estimatedweight: "",
          as_id: "",
        },
      ];
      this.remark = "";
      this.departurefrom = this.itemsListAirport[0];
      this.destination = this.itemsListAirport[0];
      this.checkboxinterfield = false;
      this.bodyweight = 0;
      this.baggageweight = 0;
    },
    async EditItem(itemPass, index) {
      this.modeEvent = "edit";
      this.dialogEvent = true;
      this.stepdialog = 1;
      this.arraychoospassenger = [
        {
          u_id: itemPass.u_id,
          FullName: itemPass.FullName,
          c_name: itemPass.c_name,
          as_id: itemPass.as_id,
        },
      ];
      this.remark = itemPass.remark;
      this.departurefrom = itemPass.ap_departairport_id;
      this.destination = itemPass.ap_destairport_id;
      this.checkboxinterfield = itemPass.checkboxinterfield;
      this.bodyweight = itemPass.bodyweight;
      this.baggageweight = itemPass.baggageweight;
      this.chooseItemPassengerIndex = index;

      //Add
      this.safetyTrainingDayLeft = itemPass.SafetyTrainingDayLeft;
      this.medicalCheckupDayLeft = itemPass.MedicalCheckupDayLeft;
    },
    async EditItemRecover(itemPass, index) {
      this.modeEvent = "edit";
      this.arraychoospassenger = [
        {
          u_id: itemPass.u_id,
          FullName: itemPass.FullName,
          c_name: itemPass.c_name,
          as_id: itemPass.as_id,
        },
      ];
      var datachoosepassenger = {};
      datachoosepassenger["IdPass"] = itemPass.IdPass;
      datachoosepassenger["u_id"] = itemPass.u_id;
      datachoosepassenger["FullName"] = itemPass.FullName;
      datachoosepassenger["c_name"] = itemPass.c_name;
      datachoosepassenger["c_id"] = itemPass.c_id;
      datachoosepassenger["p_imageurl"] = itemPass.p_imageurl;
      datachoosepassenger["remark"] = itemPass.remark;
      datachoosepassenger["ap_departairport_id"] = itemPass.ap_departairport_id;
      datachoosepassenger["departurefrom"] = itemPass.departurefrom;
      datachoosepassenger["ap_destairport_id"] = itemPass.ap_destairport_id;
      datachoosepassenger["destination"] = itemPass.destination;
      datachoosepassenger["checkboxinterfield"] = itemPass.checkboxinterfield;
      datachoosepassenger["bodyweight"] = itemPass.bodyweight;
      datachoosepassenger["baggageweight"] = itemPass.baggageweight;
      datachoosepassenger["as_id"] = itemPass.as_id;
      datachoosepassenger["p_checkin"] = itemPass.p_checkin;
      datachoosepassenger["p_offload"] = null;

      //Add
      datachoosepassenger["SafetyTrainingDayLeft"] =
        itemPass.SafetyTrainingDayLeft;
      datachoosepassenger["MedicalCheckupDayLeft"] =
        itemPass.MedicalCheckupDayLeft;

      this.ArrlistPassenger[index] = datachoosepassenger;
    },
    async ChoosePassenger(dataUser) {
      this.dialogSelectPassenger = false;
      this.arraychoospassenger = [];
      this.arraychoospassenger.push(dataUser);
      this.bodyweight = dataUser.u_estimatedweight;
    },
    async SaveToArrayPassenger() {
      if (this.modeEvent == "create") {
        // var datachoosepassenger = {};
        // datachoosepassenger["IdPass"] = null;
        // datachoosepassenger["u_id"] = this.arraychoospassenger[0].u_id;
        // datachoosepassenger["FullName"] = this.arraychoospassenger[0].FullName;
        // datachoosepassenger["c_name"] = this.arraychoospassenger[0].c_name;
        // datachoosepassenger["c_id"] = this.arraychoospassenger[0].c_id;
        // datachoosepassenger[
        //   "p_imageurl"
        // ] = this.arraychoospassenger[0].p_imageurl;
        // datachoosepassenger["remark"] = this.remark;
        // datachoosepassenger["ap_departairport_id"] = this.departurefrom.ap_id;
        // datachoosepassenger["departurefrom"] = this.departurefrom.ap_name;
        // datachoosepassenger["ap_destairport_id"] = this.destination.ap_id;
        // datachoosepassenger["destination"] = this.destination.ap_name;
        // datachoosepassenger["checkboxinterfield"] = this.checkboxinterfield;
        // datachoosepassenger["bodyweight"] = this.bodyweight;
        // datachoosepassenger["baggageweight"] = this.baggageweight;
        // datachoosepassenger["as_id"] = this.arraychoospassenger[0].as_id;

        // //Add
        // datachoosepassenger[
        //   "SafetyTrainingDayLeft"
        // ] = this.safetyTrainingDayLeft;
        // datachoosepassenger[
        //   "MedicalCheckupDayLeft"
        // ] = this.medicalCheckupDayLeft;

        // this.ArrlistPassenger.push(datachoosepassenger);

        //Edit
        var DataAdd = {
          f_id: this.flightId,
          u_id: this.arraychoospassenger[0].u_id,
          c_id: this.arraychoospassenger[0].c_id,
          ap_departairport_id: this.departurefrom.ap_id,
          ap_destairport_id: this.destination.ap_id,
          pt_id: 1,
          p_body_weight: 0,
          p_baggage_weight: 0,
          p_remark: this.remark,
          p_imageurl: this.arraychoospassenger[0].u_imageurl,
          p_inter_field: this.checkboxinterfield === false ? 0 : 1,
        };

        await this.CreatePassenger(DataAdd);
        this.DataPassengerFromDB(this.flightId);
      } else {
        var datachoosepassenger = {};
        datachoosepassenger["IdPass"] =
          this.ArrlistPassenger[this.chooseItemPassengerIndex].IdPass;
        datachoosepassenger["u_id"] = this.arraychoospassenger[0].u_id;
        datachoosepassenger["FullName"] = this.arraychoospassenger[0].FullName;
        datachoosepassenger["c_name"] = this.arraychoospassenger[0].c_name;
        datachoosepassenger["c_id"] = this.arraychoospassenger[0].c_id;
        datachoosepassenger["p_imageurl"] =
          this.arraychoospassenger[0].p_imageurl;
        datachoosepassenger["remark"] = this.remark;
        datachoosepassenger["ap_departairport_id"] = this.departurefrom.ap_id;
        datachoosepassenger["departurefrom"] = this.departurefrom.ap_name;
        datachoosepassenger["ap_destairport_id"] = this.destination.ap_id;
        datachoosepassenger["destination"] = this.destination.ap_name;
        datachoosepassenger["checkboxinterfield"] = this.checkboxinterfield;
        datachoosepassenger["bodyweight"] = this.bodyweight;
        datachoosepassenger["baggageweight"] = this.baggageweight;
        datachoosepassenger["as_id"] = this.arraychoospassenger[0].as_id;

        //Add
        datachoosepassenger["SafetyTrainingDayLeft"] =
          this.arraychoospassenger[0].SafetyTrainingDayLeft;
        datachoosepassenger["MedicalCheckupDayLeft"] =
          this.arraychoospassenger[0].MedicalCheckupDayLeft;

        this.ArrlistPassenger[this.chooseItemPassengerIndex] =
          datachoosepassenger;
      }
      await this.SumDataBodyWeightPassenger();
      await this.SumDataBaggageWeightPassenger();
      await this.SumDataLegInAndOut();
      await this.CountDataBaggageWeightPassenger();
    },

    async SumDataLegInAndOut() {
      return this.$emit("SumDataLegInAndOut", null);
    },

    async SumDataBodyWeightPassenger() {
      this.sumbodyweight = 0;

      // var weight = this.ArrlistPassenger.reduce(function (prev, cur) {
      //   return prev + cur.bodyweight;
      // }, 0);

      //Edit
      var weight = 0;
      this.ArrlistPassenger.forEach((element) => {
        if (element.p_offload == null) {
          weight += element.bodyweight;
        }
      });
      this.sumbodyweight = weight;
      return this.$emit("SumDataBodyWeightOutbound", this.sumbodyweight);
    },
    async SumDataBaggageWeightPassenger() {
      this.sumbaggageweight = 0;
      let weight = 0;
      this.ArrlistPassenger.forEach((element) => {
        if (element.p_offload == null) {
          if (element.baggageweights.length > 0) {
            element.baggageweights.forEach((element2) => {
              if (JSON.stringify(element2) != "{}") {
                let b = element2.weight.trim().split(" ");
                weight += parseFloat(b[0]);
              }
            });
          }
        }
      });
      this.sumbaggageweight = weight;
      return this.$emit("SumDataBaggageWeightOutbound", this.sumbaggageweight);
    },
    async CountDataBaggageWeightPassenger() {
      var countbaggage = 0
      this.ArrlistPassenger.filter(x => x.p_offload == null).forEach(x => {
        countbaggage = x.baggageweights.filter(x => x.weight != null).length + countbaggage
      })
      //return true;
      return this.$emit("CountDataBaggageWeightOutbound", countbaggage);
    },
    async DeleteItem(itemPass) {
      const index = this.ArrlistPassenger.indexOf(itemPass);
      if (index > -1) {
        this.ArrlistPassenger.splice(index, 1);
      }
      this.SumDataBodyWeightPassenger();
      this.SumDataBaggageWeightPassenger();
      this.CountDataBaggageWeightPassenger();
    },
    async CreatePassengerRawData(Id) {
      try {
        this.ArrlistPassenger.forEach((element) => {
          var DataAdd = {
            u_id: element.u_id,
            f_id: Id,
            c_id: element.c_id,
            ap_departairport_id: element.ap_departairport_id,
            ap_destairport_id: element.ap_destairport_id,
            pt_id: 1,
            p_body_weight: element.bodyweight,
            p_baggage_weight: element.baggageweight,
            remark: element.remark,
            p_imageurl: element.imgurl,
            p_inter_field: this.checkboxinterfield === false ? 0 : 1,
          };
          this.CreatePassenger(DataAdd);
        });
      } catch (error) {
        console.log(error);
      }
    },
    async CreatePassenger(DataAdd) {
      try {
        var res = await feathersClientUOA.service("passenger").create(DataAdd);
      } catch (error) {
        console.log(error);
      }
    },
    async DataPassengerFromDB(idflight) {
      try {
        var q = {};
        q.f_id = idflight;
        q.pt_id = 1;
        q.retire = 0;
        var res = await feathersClientUOA
          .service("viewcheckinpassenger")
          .find({ query: q });

        this.itemsDataPassenger = res.data;
        this.ArrlistPassenger = [];
        this.itemsDataPassenger.forEach((element) => {
          var datachoosepassenger = {};
          datachoosepassenger["IdPass"] = element.p_id;
          datachoosepassenger["u_id"] = element.u_id;
          datachoosepassenger["u_firstname"] = element.u_firstname;
          datachoosepassenger["u_lastname"] = element.u_lastname;
          datachoosepassenger["FullName"] =
            element.u_firstname + " " + element.u_lastname;
          datachoosepassenger["c_name"] = element.c_name;
          datachoosepassenger["remark"] = element.p_remark;
          datachoosepassenger["ap_departairport_id"] =
            element.ap_departairport_id;
          datachoosepassenger["departurefrom"] = element.ap_departairport_name;
          datachoosepassenger["ap_destairport_id"] = element.ap_destairport_id;
          datachoosepassenger["destination"] = element.ap_destairport_name;
          datachoosepassenger["bodyweight"] = element.p_body_weight;
          datachoosepassenger["baggageweight"] = element.p_baggage_weight;
          datachoosepassenger["c_id"] = element.c_id;
          datachoosepassenger["p_imageurl"] = element.p_imageurl;
          datachoosepassenger["checkboxinterfield"] = element.p_inter_field;
          datachoosepassenger["as_id"] = element.as_id;
          datachoosepassenger["p_checkin"] = element.p_checkin;
          datachoosepassenger["p_offload"] = element.p_offload;

          //Add
          datachoosepassenger["SafetyTrainingDayLeft"] =
            element.SafetyTrainingDayLeft;
          datachoosepassenger["MedicalCheckupDayLeft"] =
            element.MedicalCheckupDayLeft;
          
          datachoosepassenger["o_id"] = element.o_id;
          datachoosepassenger["o_name"] = element.o_name;
          datachoosepassenger["u_imageurl"] = element.u_imageurl;

          datachoosepassenger["p_seat_no"] = element.p_seat_no;

          datachoosepassenger["as_name"] = element.as_name;
          datachoosepassenger["p_checkin"] = element.p_checkin;
          datachoosepassenger["p_checkin_date"] = element.p_checkin_date;
          datachoosepassenger["p_rfid"] = element.p_rfid;
          datachoosepassenger["retire"] = element.retire;

          // let arrayBags = element.p_baggage_weights.split(",");

          let arrayBags = [];
          if (element.p_baggage_weights != null) {
            arrayBags = element.p_baggage_weights.split(",");
          }

          if (arrayBags.length > 1) {
            let bags = [];
            arrayBags.forEach((element) => {
              let bag = {};
              bag.weight = element;
              bags.push(bag);
            });
            datachoosepassenger["baggageweights"] = bags;
            // alert("1 : " + JSON.stringify(datachoosepassenger.baggageweights))
          } else {
            let bags2 = [];
            let bag2 = { weight: 0 };
            bag2.weight = arrayBags[0];
            bags2.push(bag2);
            datachoosepassenger["baggageweights"] = bags2;
            // alert("2 : " + JSON.stringify(datachoosepassenger.baggageweights))
          }

          //FOR MUBADALA
          //datachoosepassenger["mubadala_status"] = element.mubadala_status;
          datachoosepassenger["mubadala_status"] = element.mubadala_status2;
          //FOR MUBADALA

          this.ArrlistPassenger.push(datachoosepassenger);

          this.ArrlistPassenger.sort(function (a, b) {
            return (
              (b.p_checkin === null) - (a.p_checkin === null) ||
              +(b.p_checkin > a.p_checkin) ||
              (-(b.p_checkin < a.p_checkin) &&
                (b.p_offload !== null) - (a.p_offload !== null)) ||
              +(b.p_offload < a.p_offload) ||
              -(b.p_offload > a.p_offload)
            );
          });
        });
        this.SumDataBodyWeightPassenger();
        this.SumDataBaggageWeightPassenger();
        this.CountDataBaggageWeightPassenger();
      } catch (error) {
        console.log(error);
      }
    },
    async GotoPersonal(Id, destination) {
      this.$router.push({ path: `/personalcheckin/${Id}/${this.o_name}/${destination}/${"false"}/${this.o_id}/${this.flightId}`});
    },
    async SavePassengerEditToDB(idflight) {
      this.idflight = idflight;
      var queryflight = { f_id: this.idflight, pt_id: 1 };
      var res = await feathersClientUOA
        .service("passenger")
        .find({ query: queryflight });
      var DataAdd = {
        retire: 1,
      };
      res.data.forEach((element) => {
        this.Updatetodb(element.p_id, DataAdd);
      });
      this.ArrlistPassenger.forEach((elementUpdate) => {
        if (elementUpdate.IdPass != null) {
          let dataUpdate = {
            c_id: elementUpdate.c_id,
            ap_departairport_id: elementUpdate.ap_departairport_id,
            ap_destairport_id: elementUpdate.ap_destairport_id,
            p_remark: elementUpdate.remark,
            p_imageurl: elementUpdate.p_imageurl,
            p_body_weight: elementUpdate.bodyweight,
            p_baggage_weight: elementUpdate.baggageweight,
            p_inter_field: elementUpdate.checkboxinterfield,
            p_offload: elementUpdate.p_offload,
            retire: 0,
          };

          this.Updatetodb(elementUpdate.IdPass, dataUpdate);
        } else if (elementUpdate.IdPass == null) {
          var DataAdd = {
            u_id: elementUpdate.u_id,
            f_id: this.idflight,
            c_id: elementUpdate.c_id,
            ap_departairport_id: elementUpdate.ap_departairport_id,
            ap_destairport_id: elementUpdate.ap_destairport_id,
            pt_id: 1,
            p_imageurl: elementUpdate.p_imageurl,
            p_body_weight: elementUpdate.bodyweight,
            p_baggage_weight: elementUpdate.baggageweight,
            p_remark: elementUpdate.remark,
            p_inter_field: elementUpdate.checkboxinterfield === false ? 0 : 1,
          };

          //alert(JSON.stringify(DataAdd));
          this.CreatePassenger(DataAdd);
        }
      });
    },
    async Updatetodb(IdPass, dataUpdate) {
      try {
        await feathersClientUOA.service("passenger").patch(IdPass, dataUpdate);
      } catch (error) {
        console.log(error);
      }
    },

    //Add
    GotoStepDialog2() {
      if (this.arraychoospassenger[0].FullName != "") {
        this.stepdialog = 2;
      }
    },

    SkipPDPA(item) {
      this.passSelect = item;
      this.dialogConfirmToSkipPDPA = true;
    },

    ConfirmToSkipPDPA() {
      this.$router.push({ path: `/personalcheckin/${this.passSelect.IdPass}/${this.o_name}/${this.passSelect.destination}/${"true"}/${this.o_id}/${this.flightId}`});
    },

    async DataBaggageFromDB(idPass) {
      this.ArrlistAddBaggage = [];
      this.itemsDataBaggage = [];

      try {
        var q = {};
        q.p_id = idPass;
        q.retire = "0";
        var res = await feathersClientUOA.service("baggage").find({ query: q });
        this.itemsDataBaggage = res.data;
        this.itemsDataBaggage.forEach((element) => {
          var newArrayBinding = {};
          newArrayBinding["Idflightbaggage"] = element.bg_id;
          newArrayBinding["bg_weight"] = parseFloat(element.bg_weight).toFixed(
            2
          );
          this.ArrlistAddBaggage.push(newArrayBinding);
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },
    async PrintLuggageTags(item) {
      await this.DataBaggageFromDB(item.IdPass);
      await PrintTagService.PrintTag("baggage", this.ArrlistAddBaggage, item.u_firstname, item.u_lastname, item.destination, item.c_name);
    },
  },
};
</script>

<style scrop>
.center {
  text-align: center;
}

.theme-btn-even.offload {
  width: 100%;
  margin: 10px 0;
}
</style>